export const routePaths = {
  root: `/`,
  login: `/login`,
  dashboard: `/dashboard`,
  mwb: `/mwb`,
  newbazaars: `/newbazaars`,
  updatebazaars: `/updatebazaars/:id`,
  wholeseller: `/wholeseller`,
  wholesellerdashboard: `/wholesellerdashboard`,
  wholesellermart: `/wholesellermart`,
  wholesellerproducts: `/wholesellerproducts`,
  itemwiseplan: `/itemwiseplan`,
  offers: `/offers`,
  createoffer: `/createoffer`,
  agents: `/agents`,
  addagent: `/addagent`,
  addagentId: `/addagent/:id`,
  updatebranch: `/updatebranch/:id`,
  agentkyc: `/agentkyc/:id`,
  agentedit: `/agentedit/:id`,
  planedit: `/planedit/:id`,
  retailers: `/retailers`,
  addretailers: `/addretailers`,
  wholesellerlist: `/wholesellerlist`,
  renewplan: `/renewplan/:id`,
  addwholeseller: `/addwholeseller`,
  Editwholeseller: `/addwholeseller/:id`,
  agentdetails: `/agentdetails/:id`,
  wholesalerdetails: `/wholesalerdetails/:id`,
  wholesalerdetailsAddons: `/wholesalerdetails/:id/addons/:planId`,
  wholesalerPlanPay: `/wholesalerdetails/:id/:type/:planId/pay/:newPlan`,
  wholesalerdetailsPlans: `/wholesalerdetails/:id/plans`,
  wholesalerPlanPlansPay: `/wholesalerdetails/:id/plans/:planId/pay`,
  wholesellerkyc: `/wholesellerkyc/:wSellerID/:type`,
  electronicsbazaar: `/electronicsbazaar/:id`,
  addproduct: `/addproduct`,
  plans: `/plans`,
  addonPlans: `/plans/:planId/addon`,
  addAddonPlans: `/plans/:planId/addon/add`,
  editAddonPlans: `/plans/:planId/addon/edit/:id`,
  reviewplan: `/reviewplan/:id`,
  viewplan: `/view-plan/:id`,
  addnewplan: `/addnewplan`,
  updateplans: `/planedit/:id`,
  updateplan: `/update-plan/:id`,
  bulkupload: `/bulkupload`,
  subcategories: `/subcategories`,
  bazaarreport: `/bazaarreport/:id`,
  addnewadvertisement: `/addnewadvertisement`,
  editadvertisement: `/advertisement/:id/edit`,
  viewadvertisement: `/advertisement/:id/view`,
  addbranch: `/addbranch`,
  paymentrequest: `/paymentrequest`,
  advertisement: `/advertisement`,
  branch: `/branch`,
  branchplan: `/branchplan`,
  createorder: `/createorder`,
  editorder: `/editorder`,
  order: `/order`,
  photoorder: `/photoorder`,
  trackorder: `/trackorder`,
  rolePermission: `/rolepermission`,
  rolesPermissions: `/rolespermissions`,
  AddRols: `/addroles`,
  EditRols: `/roles/:id/edit`,
  makepayment: `/makepayment`,
  CreateAdclient: `/createadclient`,
  editAdclient: `/adclient/:id/edit`,
  viewAdclient: `/adclient/:id/view`,
  CreateAdsplan: `/createadsplan`,
  EditAdsplan: `/adsplan/:id/edit`,
  ViewAdsplan: `/adsplan/:id/view`,
  Createinvoiceview: `/invoiceview/:id`,
  CreateinvoiceviewData: `/invoiceData/:id`,
  payInvoice: `/adinvoice/:id/pay/`,
  admin: {
    bazaars: `/bazaars`,
    masterList: `/admin/master-list`,
  },
  agentCommissionRedeemModel: `/agent-commission-redeem-model`,
  createAgentCommissionRedeemModel: `/agent-commission-redeem-model/form`,
  // addOnPlans: '/plans/:planId/addon-plans',
  // addAddOnPlan: '/plans/:planId/addon-plans/add',
  // EditAddOnPlan: '/plans/:planId/addon-plans/:id'
};
