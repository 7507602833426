import React, { useEffect, useState } from "react";
import { useBazaarStepperdStyles } from "@/static/stylesheets/molecules";
import LogoPrev from "@/static/icons/ic_previous.png";
import IcCheckMark from "@/static/svg/ic_checkmark.svg";
import { DashboardLayout } from "@/components/layouts";
import { SectionHeader } from "@/components/molecules/Bazaars";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import { formatIndianRupee } from "../AgentDetails/AgentReport";
import { routePaths } from "@/routes";

export default function ViewPlan() {
  const classes = useBazaarStepperdStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [statedata, setstatedata] = useState<any>([]);
  const [bazaardata, setbazaardata] = useState<any>({});
  const [plan, setPlan] = useState<any>({});

  // console.log(id, "bazaardata");

  const getalldis = async (p: any) => {
    console.log("running getalldis");

    const res = await AppService.getAllDistricByState({
      ids: (p.bazaar_assigned_data[0]?.state ?? []).join(","),
    });
    const response = await AppService.getAllBazaars();
    console.log(response.data, "response");
    setstatedata(res.data.results);
    setbazaardata(response.data.results);
  };

  const Navigatefuc = () => {
    navigate(routePaths.plans);
  };

  const getPlanById = async () => {
    try {
      const responseJson = await AppService.getPlanById(id);

      if (responseJson) {
        console.log(responseJson.data, "responseJson.data");
        setPlan(responseJson.data);
        getalldis(responseJson.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getPlanById();
    }
  }, [id]);

  return (
    <DashboardLayout>
      <SectionHeader />
      <div className={classes.root}>
        <div
          className="headContainer"
          onClick={Navigatefuc}
          style={{ alignItems: "center", marginBottom: "15px" }}
        >
          <div className="icon">
            <img src={LogoPrev} alt={"Logo"} />
          </div>
          <div className="headTitle">View Plan</div>
        </div>
        {plan && (
          <Grid
            container
            spacing={2}
            className="review-plans"
            style={{ marginRight: 0, paddingBottom: "30px" }}
          >
            <Grid item lg={4} md={4} sm={6} style={{ paddingTop: 0 }}>
              <div className="pt-[30px] h-full ">
                <div
                  className={`border border-[#4E2FA9] rounded-xl p-[30px] h-full`}
                >
                  {plan?.plantype !== "FREE" ? (
                    <div
                      className="flex justify-between"
                      style={{ alignItems: "center" }}
                    >
                      <div>
                        <p className="text-[#4E2FA9] text-[16px] font-bold">
                          {plan?.plan_name}
                        </p>

                        <p className="text-[#121127] text-[18px] font-semibold pt-3">
                          {bazaardata?.bazaar_name}
                        </p>
                      </div>
                      <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                        <p className="text-[#FF6652] text-[28px] font-bold leading-none">
                          {plan?.plan_choice == "FREE" ? (
                            plan?.plan_choice
                          ) : (
                            <>
                              {" "}
                              <p className="text-[#FF6652] text-[28px]">
                                ₹{plan?.amount}
                              </p>
                              <p className="text-[#84818A] text-xs mt-1 font-semibold">
                                {plan.plan_periods_in_days} Days
                              </p>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-5 justify-between">
                      <div>
                        <p className="text-[#4E2FA9] text-[16px] font-semibold">
                          Individual
                        </p>
                        <p className="text-[#121127] text-[24px] font-semibold pt-2">
                          Free
                        </p>
                      </div>
                    </div>
                  )}
                  <div>
                    <p className="text-[#121127] text-[14px] font-semibold pt-4">
                      Available for{" "}
                      {statedata
                        ?.map((item: any) => item.state_name)
                        ?.join(", ")}
                    </p>
                  </div>
                  <div className="mt-[100px]">
                    <p className="font-[#121127] text-[24px] font-bold mb-2">
                      Features
                    </p>
                    <div
                      className="flex gap-5 pb-6 all-features-div"
                      style={{ height: "384px", overflowY: "auto" }}
                    >
                      <ul>
                        {plan?.plan_tally && (
                          <>
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  Tally Support
                                </p>
                              </div>
                            </li>
                          </>
                        )}

                        {!!plan?.whatsapp_notification_limit && (
                          <>
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {plan?.whatsapp_notification_limit} Whatsapp
                                  Notification
                                </p>
                              </div>
                            </li>
                          </>
                        )}

                        {!!plan?.sms_notification_limit && (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.sms_notification_limit} SMS Notification
                              </p>
                            </div>
                          </li>
                        )}

                        {!!plan?.agents_limit && (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.agents_limit} Agents
                              </p>
                            </div>
                          </li>
                        )}

                        {!!plan?.products_limit && (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.products_limit} Products
                              </p>
                            </div>
                          </li>
                        )}

                        {!!plan?.orders_limit ? (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.orders_limit} Order
                              </p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        {!!plan?.branches ? (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.branches} Branches
                              </p>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        {!!plan?.retailers_limit && (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.retailers_limit} Retailers
                              </p>
                            </div>
                          </li>
                        )}

                        {!!plan?.users_limit && (
                          <li className="mb-[5px]">
                            <div className="flex gap-4 items-center">
                              <img
                                className="w-[15px]"
                                src={IcCheckMark}
                                alt={"Logo"}
                              />
                              <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                {plan?.users_limit} Users
                              </p>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </DashboardLayout>
  );
}
