import React, { useEffect, useState } from "react";
import { useKycFormStyles } from "@/static/stylesheets/molecules";
import {
  FormControl,
  Input,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  TextField,
  Box,
  Dialog,
  DialogTitle,
} from "@mui/material";
import LogoDelete from "@/static/icons/ic_delete.png";
import LogoEdit from "@/static/icons/ic_edit.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppService } from "@/service/AllApiData.service";
import { ActionButton } from "../../components/atoms/Button/ActionButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ErrorMessage, Form, Formik } from "formik";
import { Alert, AlertError } from "@/alert/Alert";
import { Grid } from "@mui/material";
import IcView from "@/static/svg/ic_view.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";
import { removeDuplicates } from "@/helper/util";
import * as Yup from "yup";
import { DashboardLayout } from "@/components/layouts";
import IcCheckMark from "@/static/svg/ic_checkmark.svg";

const AddAddonPlan = () => {
  const classes = useKycFormStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [showPreview, setShowPreview] = useState<any>(false);
  const [initialValues, setInitialValues] = useState({
    plan_name: "",
    plan_tally: false,
    amount: null,
    branches: null,
    user_per_branch: null,
    plan_active: false,
    plan_periods_in_days: null,
    whatsapp_notification: false,
    whatsapp_notification_limit: null,
    sms_notification: false,
    sms_notification_limit: null,
    email_notification_limit: null,
    agents_limit: null,
    products_limit: null,
    retailers_limit: null,
    users_limit: null,
    orders_limit: null,
  });

  const formSchema = Yup.object({
    plan_name: Yup.string().required("Plan name is requied"),
    amount: Yup.string().required("Amount is requied"),
  });

  const getPlan = async () => {
    try {
      const response = await AppService.getSingleAddonPlans(params.id);
      console.log("Logger123 ====> ", response.data);
      setInitialValues({
        plan_name: response.data.plan_name,
        plan_tally: response.data.plan_tally,
        amount: response.data.amount,
        branches: response.data.branches,
        user_per_branch: response.data.user_per_branch,
        plan_active: response.data.plan_active,
        plan_periods_in_days: response.data.plan_periods_in_days,
        whatsapp_notification: response.data.whatsapp_notification,
        whatsapp_notification_limit: response.data.whatsapp_notification_limit,
        sms_notification: response.data.sms_notification,
        sms_notification_limit: response.data.sms_notification_limit,
        email_notification_limit: response.data.email_notification_limit,
        agents_limit: response.data.agents_limit,
        products_limit: response.data.products_limit,
        retailers_limit: response.data.retailers_limit,
        users_limit: response.data.users_limit,
        orders_limit: response.data.orders_limit,
      });
    } catch (err: any) {
      AlertError(err?.response?.data?.details || err?.message);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let payload = { ...values };
      payload.plan = [Number(params.planId)];
      if (params.id) {
        const response = await AppService.updateAddonPlan(params.id, payload);
        Alert("Plan Addon updated successfully");
      } else {
        const response = await AppService.CreateAddonPlan(payload);
        Alert("Plan Addon created successfully");
      }
      navigate(`/plans/${params.planId}/addon`);
    } catch (err: any) {
      AlertError(err?.response?.data?.details || err?.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getPlan();
    }
  }, [params]);

  return (
    <DashboardLayout>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {({ values, errors, handleChange, handleSubmit, isValid }) => (
            <div className={classes.root}>
              {/* <form onSubmit={handleSubmit}> */}

              <div className="mb-[32px]">
                <div
                  className="flex align-middle justify-between"
                  style={{ alignItems: "center" }}
                >
                  <p className="commonTitle text-[28px] font-bold">Add Addon</p>
                </div>
              </div>

              <div>
                <div
                  className="bazaarField flex items-center gap-8 bazaarplan-form"
                  style={{ width: "100%", display: "block" }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      lg={3.25}
                      md={5}
                      sm={6}
                      className="bazaarplan-div"
                    >
                      <div style={{ paddingRight: "15px" }}>
                        <p className="fieldTitle">
                          Plan Name<span className="text-red-600">*</span>
                        </p>
                        <Input
                          className="w-full"
                          id="plan_name"
                          type="text"
                          name="plan_name"
                          required
                          error={!!errors.plan_name}
                          value={values.plan_name}
                          onChange={handleChange}
                        />
                        {errors.plan_name && (
                          <div className=" text-red-600">
                            {errors.plan_name}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={3.25}
                      md={5}
                      sm={6}
                      className="bazaarplan-div"
                    >
                      <div style={{ paddingRight: "15px" }}>
                        <p className="fieldTitle">
                          Amount <span className="text-red-600">*</span>
                        </p>
                        <Input
                          className="w-full"
                          id="amount"
                          type="number"
                          name="amount"
                          required
                          error={!!errors.amount}
                          value={values.amount ? values.amount : ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.amount && (
                          <div className=" text-red-600">{errors.amount}</div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="mt-[20px] items-center ml-[32px]">
                <p className="actionButton pt-[20px] pb-[20px]">
                  Plan Features
                </p>

                <div className="grid md:grid-cols-2">
                  <div className="grid md:grid-cols-2">
                    <div
                      style={{
                        paddingTop: "30px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={values?.whatsapp_notification}
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          })
                        }
                        className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                        name="whatsapp_notification"
                        id="whatsapp_notification"
                      />
                      <label
                        htmlFor="whatsapp_notification"
                        className="fieldTitle !text-[16px]"
                      >
                        Whatsapp Notifications
                      </label>
                    </div>

                    {values?.whatsapp_notification && (
                      <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                        <p className="fieldTitle">
                          Number of Whatsapp Notifications
                        </p>
                        <Input
                          className="w-full"
                          id="whatsapp_notification_limit"
                          type="number"
                          name="whatsapp_notification_limit"
                          required
                          error={!!errors.whatsapp_notification_limit}
                          value={
                            values.whatsapp_notification_limit
                              ? values.whatsapp_notification_limit
                              : ""
                          }
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.whatsapp_notification_limit && (
                          <div className=" text-red-600">
                            {errors.whatsapp_notification_limit}
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        paddingTop: "30px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={values?.sms_notification}
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          })
                        }
                        className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                        name="sms_notification"
                        id="sms_notification"
                      />
                      <label
                        htmlFor="sms_notification"
                        className="fieldTitle !text-[16px]"
                      >
                        SMS Notifications
                      </label>
                    </div>

                    {values?.sms_notification && (
                      <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                        <p className="fieldTitle">
                          Number of SMS Notifications
                        </p>
                        <Input
                          className="w-full"
                          id="sms_notification_limit"
                          type="number"
                          name="sms_notification_limit"
                          required
                          error={!!errors.sms_notification_limit}
                          value={
                            values.sms_notification_limit
                              ? values.sms_notification_limit
                              : ""
                          }
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errors.sms_notification_limit && (
                          <div className=" text-red-600">
                            {errors.sms_notification_limit}
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        paddingTop: "30px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={values.plan_tally}
                        onChange={(e) => handleChange(e)}
                        className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                        name="plan_tally"
                        id="telly"
                      />
                      <label
                        htmlFor="telly"
                        className="fieldTitle !text-[16px]"
                      >
                        Add Tally
                      </label>
                    </div>

                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Number of Branch</p>
                      <Input
                        className="w-full"
                        id="branches"
                        type="number"
                        name="branches"
                        required
                        error={!!errors.branches}
                        value={values.branches ? values.branches : ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.branches && (
                        <div className=" text-red-600">{errors.branches}</div>
                      )}
                    </div>

                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Number of Agents</p>
                      <Input
                        className="w-full"
                        id="agents_limit"
                        type="number"
                        name="agents_limit"
                        required
                        error={!!errors.agents_limit}
                        value={values.agents_limit ? values.agents_limit : ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.agents_limit && (
                        <div className=" text-red-600">
                          {errors.agents_limit}
                        </div>
                      )}
                    </div>

                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Number of Products</p>
                      <Input
                        className="w-full"
                        id="products_limit"
                        type="number"
                        name="products_limit"
                        required
                        error={!!errors.products_limit}
                        value={
                          values.products_limit ? values.products_limit : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.products_limit && (
                        <div className=" text-red-600">
                          {errors.products_limit}
                        </div>
                      )}
                    </div>

                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Number of Retailers</p>
                      <Input
                        className="w-full"
                        id="retailers_limit"
                        type="number"
                        name="retailers_limit"
                        required
                        error={!!errors.retailers_limit}
                        value={
                          values.retailers_limit ? values.retailers_limit : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.retailers_limit && (
                        <div className=" text-red-600">
                          {errors.retailers_limit}
                        </div>
                      )}
                    </div>

                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Number of Users</p>
                      <Input
                        className="w-full"
                        id="users_limit"
                        type="number"
                        name="users_limit"
                        required
                        error={!!errors.users_limit}
                        value={values.users_limit ? values.users_limit : ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.users_limit && (
                        <div className=" text-red-600">
                          {errors.users_limit}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Dialog
                open={showPreview}
                onClose={() => setShowPreview(false)}
                fullWidth
              >
                <DialogTitle>Addon Plan Preview</DialogTitle>

                <div className="h-full w-[400px] mx-auto mb-[50px]">
                  <div
                    className={`border border-[#4E2FA9] rounded-xl p-[30px] h-full`}
                  >
                    <div
                      className="flex justify-between"
                      style={{ alignItems: "center" }}
                    >
                      <div>
                        <p className="text-[#4E2FA9] text-[22px] font-bold">
                          {values?.plan_name}
                        </p>
                      </div>
                      <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                        <p className="text-[#FF6652] text-[28px] font-bold leading-none">
                          <p className="text-[#FF6652] text-[28px]">
                            ₹{values?.amount}
                          </p>
                          {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {localStoragedata.plan_periods_in_days}
                      </p> */}
                          {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                                            {localStoragedata.plan_periods_in_days} Days
                                          </p> */}
                          {/* </> */}
                        </p>
                      </div>
                    </div>

                    <div className="mt-[40px]">
                      <p className="font-[#121127] text-[24px] font-bold mb-2">
                        Features
                      </p>
                      <div
                        className="flex gap-5 pb-6 all-features-div"
                        style={{ overflowY: "auto" }}
                      >
                        <ul>
                          {/* {previewData?.featureData?.length > 0 ? (
                        previewData?.featureData.map((it: any) => ( */}
                          {values?.plan_tally && (
                            <>
                              <li className="mb-[5px]">
                                <div className="flex gap-4 items-center">
                                  <img
                                    className="w-[15px]"
                                    src={IcCheckMark}
                                    alt={"Logo"}
                                  />
                                  <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                    Tally Support
                                  </p>
                                </div>
                              </li>
                            </>
                          )}
                          {values?.whatsapp_notification_limit && (
                            <>
                              <li className="mb-[5px]">
                                <div className="flex gap-4 items-center">
                                  <img
                                    className="w-[15px]"
                                    src={IcCheckMark}
                                    alt={"Logo"}
                                  />
                                  <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                    {values?.whatsapp_notification_limit}{" "}
                                    Whatsapp Notification
                                  </p>
                                </div>
                              </li>
                            </>
                          )}

                          {values?.sms_notification_limit && (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.sms_notification_limit} SMS
                                  Notification
                                </p>
                              </div>
                            </li>
                          )}

                          {values?.agents_limit && (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.agents_limit} Agents
                                </p>
                              </div>
                            </li>
                          )}

                          {values?.products_limit && (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.products_limit} Products
                                </p>
                              </div>
                            </li>
                          )}

                          {values?.orders_limit ? (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.orders_limit} Order
                                </p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}

                          {values?.branches ? (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.branches} Branches
                                </p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}

                          {values?.retailers_limit && (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.retailers_limit} Retailers
                                </p>
                              </div>
                            </li>
                          )}

                          {values?.users_limit && (
                            <li className="mb-[5px]">
                              <div className="flex gap-4 items-center">
                                <img
                                  className="w-[15px]"
                                  src={IcCheckMark}
                                  alt={"Logo"}
                                />
                                <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                  {values?.users_limit} Users
                                </p>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>

              <button
                className={
                  values.plan_name && values.amount
                    ? "flex mt-[25px] w-[250px] gap-5 items-center border-2 border-[#4E2FA9] rounded-[11px] border-dashed py-[8px] px-[20px] cursor-pointer"
                    : "flex mt-[25px] w-[250px] gap-5 items-center border-2 border-[#4E2FA9] rounded-[11px] border-dashed py-[8px] px-[20px] cursor-not-allowed"
                }
                onClick={() => setShowPreview(true)}
                disabled={!(values.plan_name && values.amount)}
              >
                <div className="w-[20px]">
                  <img src={IcView} alt={"Logo"} />
                </div>
                <div className="privacyButton">Preview</div>
              </button>

              <div className="flex gap-5 py-[30px] ml-[32px]">
                <ActionButton
                  title="Cancel"
                  variant="primary"
                  onClick={() => navigate(`/plans/${params.planId}/addon`)}
                />
                <ActionButton
                  type="submit"
                  title="Submit"
                  variant="default"
                  onClick={handleSubmit}
                />
              </div>
              {/* </form> */}
            </div>
          )}
        </Formik>
      </LocalizationProvider>
    </DashboardLayout>
  );
};

export default AddAddonPlan;
