import React, { SyntheticEvent, useEffect, useState } from "react";
import { useKycFormStyles } from "@/static/stylesheets/molecules";
import {
  FormControl,
  Input,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import LogoDelete from "@/static/icons/ic_delete.png";
import LogoEdit from "@/static/icons/ic_edit.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppService } from "@/service/AllApiData.service";
import { ActionButton } from "../../atoms/Button/ActionButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ErrorMessage, Form, Formik } from "formik";
import { Alert, AlertError } from "@/alert/Alert";
import { Grid } from "@mui/material";
import IcView from "@/static/svg/ic_view.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";
import { removeDuplicates } from "@/helper/util";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
const FreePlan = (props: any) => {
  const {
    errors,
    setFieldValue,
    handleChange,
    values,
    handleSubmit,
    id,
    AllBazaar,
    feature,
    setFeature,
    settally,
    tally,
    previewData,
    setPreviewData,
    touched,
    isValid,
    bazaarWiseData,
    setBazaarWiseData,
    isLocked,
    handleBlur,
    bazaarData,
  } = props;
  const classes = useKycFormStyles();
  const navigate = useNavigate();
  const datas = useParams();

  console.log(values, "values", feature);

  const [bazaars, setBazaars] = useState<any>([]);

  useEffect(() => {
    FeaturesAlldatas();
  }, []);

  const superAdmin = true;
  const agency = JSON.parse(localStorage.getItem("agency") || "{}");

  const allowedStates = agency?.agent_assigned_state_names?.map(
    (item: any, index: number) => ({
      label: item.state,
      value: item.id,
    })
  );

  useEffect(() => {
    console.log("Logger123....bazarr ===> ", values.bazaar);
    if (values.bazaar.length) {
      bazaargetdata({ ids: values.bazaar.join(",") });
    }
  }, [values.bazaar]);

  const bazaargetdata = async (params: any) => {
    try {
      const responseJson = await AppService.getAllStateByBazaar(params);

      const allowedIds = allowedStates?.map((item: any) => item.value);

      const tempState = responseJson.data.results
        .flatMap((row: any) =>
          row.bazaar_state_data.map((item: any) => {
            // const stateItem: any = singleState?.find((item: any) => item.id === id);
            if (allowedIds?.includes(item.id) || superAdmin) {
              return {
                bazaar_id: row.bazaar,
                label: item?.state,
                value: item?.id,
              };
            }
            return null; // Handle cases where a matching state is not found
          })
        )
        .filter((item: any) => item !== null);

      console.log("logger123...", allowedIds, tempState);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  // useEffect(() => {
  //   getDistrictByStates();
  // }, [states]);

  // useEffect(() => {
  //   getAllCitys();
  // }, [districts]);

  const FeaturesAlldatas = async () => {
    const renssponseJson = await AppService.listFeaturesAll();
    let data = Math.ceil(renssponseJson.data.count / 10);

    for (let i = 0; i < data; i++) {
      const data = await AppService.listFeaturesAll({ page: i + 1 });
    }
  };

  const getAllPlan = async () => {
    const responseJson = await AppService.listFeatures();
    let tempObj = {};
    await Promise.all(
      responseJson.data.results.map(async (row: any, index: number) => {
        tempObj = { ...tempObj, [index]: { id: row.id, feature: row.feature } };
      })
    );
  };

  const getAllDis = async (param: any, type: any, index: number = 0) => {
    console.log("ppppppppparrararams", param);

    const responseJson = await AppService.getAllDistricByState(param);
    console.log("all Districtss and single-----");

    if (type == "multiple") {
      console.log("all Districtss and single-----ALLL");
      bazaarWiseData[index].allDistricts = responseJson.data.results;
      setBazaarWiseData([...bazaarWiseData]);
    } else {
      console.log("all Districtss and single-----SINGLL");
    }
  };

  const getAllCity = async (param: any, type: any, index: number = 0) => {
    const responseJson = await AppService.getAllCityByDis(param);
    if (type == "multiple") {
      bazaarWiseData[index].allCities = responseJson.data.results;
      setBazaarWiseData([...bazaarWiseData]);
    } else {
    }
  };

  React.useEffect(() => {
    getAllPlan();
  }, []);

  React.useEffect(() => {
    getAllDis({ ids: values?.state }, "");
  }, [values?.state]);
  React.useEffect(() => {
    getAllCity({ ids: values?.district }, "");
  }, [values?.district]);

  const handlechagetelly = (e: any) => {
    const { name, checked } = e.target;
    if (name === "plan_tally") {
      settally(checked);
      console.log("logger123...", checked);
    }
  };

  const getplanlistbyid = async (id: any) => {
    const data = await AppService?.getPlanById(id);
    if (data?.status === 200) {
      setFeature([...data?.data?.plan_features]);
      settally(data?.data?.plan_tally);
    }
  };
  React.useEffect(() => {
    if (id !== "") {
      setFeature([]);
      getplanlistbyid(id);
    }
  }, []);

  console.log(datas.id, "previewData");

  const hendalPreview = (data: any) => {
    navigate(`/reviewplan/${datas?.id || "preview"}`);
    localStorage.setItem(
      "setplandata",
      JSON.stringify({
        ...values,
        plan_tally: tally,
        bazaar_assigned_data: bazaarWiseData,
      })
    );
    localStorage.setItem("previewData", JSON.stringify(previewData));
  };

  function removeDuplicates<T>(array: T[]): T[] {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }

  const handleStateChange = (
    list: any,
    e: any,
    index: any,
    city = false,
    type: any = ""
  ) => {
    if (e.length > 1) {
      if (type?.all === true) {
        if (city) {
          bazaarWiseData[index].city = removeDuplicates([...list, ...e]);
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = removeDuplicates([...list, ...e]);
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity(
            { ids: bazaarWiseData[index].district?.join(",") },
            "multiple",
            index
          );
        }
      } else {
        if (city) {
          bazaarWiseData[index].city = [
            ...list.filter((item: any) => !e.includes(item)),
          ];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [
            ...list.filter((item: any) => !e.includes(item)),
          ];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity(
            { ids: bazaarWiseData[index].district?.join(",") },
            "multiple",
            index
          );
        }
      }
    } else {
      if (list.includes(e[0])) {
        if (city) {
          bazaarWiseData[index].city = [
            ...list.filter((item: any) => item !== e[0]),
          ];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [
            ...list.filter((item: any) => item !== e[0]),
          ];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity(
            { ids: bazaarWiseData[index].district?.join(",") },
            "multiple",
            index
          );
        }
      } else {
        if (city) {
          bazaarWiseData[index].city = [...list, e[0]];
          setBazaarWiseData([...bazaarWiseData]);
        } else {
          bazaarWiseData[index].district = [...list, e[0]];
          setBazaarWiseData([...bazaarWiseData]);
          getAllCity(
            { ids: bazaarWiseData[index].district?.join(",") },
            "multiple",
            index
          );
        }
      }
    }

    console.log("logger123..", list);
  };

  useEffect(() => {
    console.log(
      "values",
      values.bazaar,
      AllBazaar?.filter((ele: any) => values.bazaar.includes(ele.id)) || []
    );
  }, [values]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={classes.root}>
        {/* <form onSubmit={handleSubmit}> */}
        <div>
          <div
            className="bazaarField flex items-center gap-8 mt-6 bazaarplan-form"
            style={{ width: "100%", display: "block" }}
          >
            {values.plan_choice === "FREE" ? (
              <>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingRight: "15px" }}>
                      <p className="fieldTitle">
                        Plan Name <span className=" text-red-600">*</span>
                      </p>
                      <Input
                        className="w-full"
                        id="plan_name"
                        type="text"
                        name="plan_name"
                        // required
                        onBlur={handleBlur}
                        error={errors.plan_name}
                        value={values.plan_name}
                        onChange={handleChange}
                      />
                      {errors.plan_name && (
                        <div className=" text-red-600">{errors.plan_name}</div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Start Date</p>
                      <DatePicker
                        className="w-full"
                        // label="Start Date"
                        minDate={new Date()}
                        inputFormat="DD/MM/YYYY"
                        value={values.start_date}
                        onChange={(e: any) => {
                          if (e?.$y && e?.$M && e?.$D) {
                            setFieldValue(
                              "start_date",
                              e.$y + "-" + (e.$M + 1) + "-" + e.$D
                            );
                            if (
                              values.end_date &&
                              new Date(values.end_date).getTime()! >
                                new Date(values.start_date).getTime()
                            ) {
                              setFieldValue("end_date", "");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant={"standard"} />
                        )}
                      />
                      {errors.start_date && (
                        <div className=" text-red-600">{errors.start_date}</div>
                      )}
                    </div>
                  </Grid>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">End Date</p>
                      <DatePicker
                        className="w-full"
                        // label="End Date"
                        inputFormat="DD/MM/YYYY"
                        minDate={
                          values.start_date
                            ? moment(new Date(values.start_date)).add(1, "day")
                            : new Date()
                        }
                        value={values.end_date}
                        onChange={(e: any) => {
                          if (e?.$y && e?.$M && e?.$D) {
                            setFieldValue(
                              "end_date",
                              e.$y + "-" + (e.$M + 1) + "-" + e.$D
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant={"standard"} />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div>
                      <p className="title-main">
                        Bazaar <span className=" text-red-600">*</span>
                      </p>
                      <Autocomplete
                        multiple
                        id="bazaar-autocomplete"
                        options={AllBazaar.map((item: any) => ({
                          label: item.label,
                          value: item.id,
                        }))}
                        getOptionLabel={(option) => option.label}
                        value={bazaars}
                        filterSelectedOptions
                        disableCloseOnSelect
                        filterOptions={(options) =>
                          options.filter(
                            (option) =>
                              !bazaars.some(
                                (selected: any) =>
                                  selected.value === option.value
                              )
                          )
                        }
                        onChange={(event: any, value: any) => {
                          setBazaars(value);
                          const values = value.map((ele: any) => ele.value);
                          let data: any = [];
                          values.forEach((item: number) => {
                            data = [
                              ...data,
                              {
                                bazaar_id: item,
                                state:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.state || [],
                                district:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.district || [],
                                city:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.city || [],
                                allDistricts:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.allDistricts || [],
                                allCities:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.allCities || [],
                              },
                            ];
                          });
                          setBazaarWiseData(data);

                          console.log("Bazaar_DATA", values, value);

                          setFieldValue("bazaar", values);
                          setFieldValue("agent_assigned_state", "");
                          setFieldValue("agent_assigned_district", []);
                          setFieldValue("agent_assigned_city", []);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" fullWidth />
                        )}
                      />

                      <Box sx={{ color: "red" }}>
                        {errors.bazaar && (
                          <div className=" text-red-600">{errors.bazaar}</div>
                        )}
                      </Box>
                    </div>
                  </Grid>
                </Grid>

                {AllBazaar.length
                  ? bazaarWiseData.map((item: any, index: number) => {
                      let bazaar: any = AllBazaar.find(
                        (ele: any) => ele.value === item.bazaar_id
                      );
                      console.log(
                        "Bazaar LOG ====> ",
                        bazaar,
                        bazaarWiseData,
                        AllBazaar
                      );
                      let allStates: any = bazaar.bazaar_state_names.map(
                        (item: any) => ({
                          label: item.state,
                          value: item.id,
                        })
                      );

                      let allDistricts: any =
                        bazaar.bazaar_district_names.filter((item: any) =>
                          bazaarWiseData[index]?.state?.includes(item.state)
                        ) || [];

                      let allCities: any =
                        bazaar.bazaar_city_names.filter((item: any) =>
                          bazaarWiseData[index]?.district?.includes(
                            item.district
                          )
                        ) || [];

                      return (
                        <div className="formContainer flex flex-col ml-[32px]">
                          <p className=" font-bold">{bazaar?.label}</p>

                          <div className="grid grid-cols-2 gap-4 w-full">
                            <div>
                              <p className="title-main">
                                Assigned States{" "}
                                <span className=" text-red-600">*</span>
                              </p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={true}
                                name="agent_assigned_state"
                                value={bazaarWiseData[index]?.state}
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  bazaarWiseData[index].state = e.target.value;
                                  bazaarWiseData[index].district =
                                    bazaarData.find(
                                      (ele: any) =>
                                        ele.state.includes(
                                          Number(e.target.value)
                                        ) && ele.bazaar_id === item.bazaar_id
                                    )?.district || [];
                                  bazaarWiseData[index].city =
                                    bazaarData.find(
                                      (ele: any) =>
                                        ele.state.includes(
                                          Number(e.target.value)
                                        ) && ele.bazaar_id === item.bazaar_id
                                    )?.city || [];
                                  setBazaarWiseData([...bazaarWiseData]);
                                  handleChange(e);
                                  getAllDis(
                                    {
                                      ids: e.target.value.join(","),
                                    },
                                    "multiple",
                                    index
                                  );
                                  setFieldValue("agent_assigned_district", []);
                                  setFieldValue("agent_assigned_city", []);
                                }}
                                renderValue={(selected) => {
                                  return allStates
                                    .filter((item: any) =>
                                      selected.includes(item.value)
                                    )
                                    .map((item: any) => item?.label)
                                    ?.join(", ");
                                }}
                              >
                                {allStates?.map((items: any) => (
                                  <MenuItem key={index} value={items.value}>
                                    <Checkbox
                                      checked={bazaarWiseData[
                                        index
                                      ]?.state?.includes(items.value)}
                                    />
                                    <ListItemText primary={items.label} />
                                  </MenuItem>
                                ))}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_state && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_state}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_state" /> */}
                              </Box>
                            </div>

                            <div>
                              <div className="Selectdistrict">
                                <DistrictPicker
                                  label={"Assigned District"}
                                  required
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allStates.map((item: any) => ({
                                    state: item.label,
                                    id: item.value,
                                  }))}
                                  selectedStates={[
                                    ...bazaarWiseData[index]?.state,
                                  ]}
                                  districts={bazaarWiseData[
                                    index
                                  ].allDistricts.map(
                                    (item: any, index: number) => {
                                      return {
                                        ...item,
                                        district:
                                          item?.district?.filter((ele: any) =>
                                            allDistricts
                                              .map((item: any) => item.id)
                                              .includes(ele.id)
                                          ) || [],
                                      };
                                    }
                                  )}
                                  selectedDistricts={
                                    bazaarWiseData[index]?.district
                                  }
                                  onSelectItem={(e: any, type: any) => {
                                    handleStateChange(
                                      bazaarWiseData[index]?.district,
                                      e,
                                      index,
                                      false,
                                      type
                                    );
                                    bazaarWiseData[index].city =
                                      bazaarData.find(
                                        (ele: any) =>
                                          ele.state.includes(
                                            Number(item.state)
                                          ) &&
                                          ele.bazaar_id === item.bazaar_id &&
                                          ele.district.some((element: any) =>
                                            bazaarWiseData[
                                              index
                                            ]?.district.includes(element)
                                          )
                                      )?.city || [];
                                    setBazaarWiseData([...bazaarWiseData]);
                                    setFieldValue("agent_assigned_city", []);
                                  }}
                                />
                              </div>

                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_district && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_district}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_district" /> */}
                              </Box>
                            </div>
                            <div>
                              <CityPickerNew
                                label={"Assigned Cities"}
                                hint={"Search"}
                                required
                                variant={"outlined"}
                                states={bazaarWiseData[index].allDistricts}
                                selectedStates={bazaarWiseData[index]?.district}
                                selectedDistricts={
                                  bazaarWiseData[index]?.district
                                }
                                districts={bazaarWiseData[index].allCities.map(
                                  (item: any, index: number) => {
                                    // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                    return {
                                      ...item,
                                      city:
                                        item?.city?.filter((ele: any) =>
                                          allCities
                                            .map((item: any) => item.id)
                                            .includes(ele.id)
                                        ) || [],
                                    };
                                  }
                                )}
                                selectedCity={bazaarWiseData[index]?.city}
                                selectedcity={bazaarWiseData[index]?.city}
                                onSelectItem={(
                                  id: any,
                                  type: { all: boolean }
                                ) =>
                                  handleStateChange(
                                    bazaarWiseData[index]?.city,
                                    id,
                                    index,
                                    true,
                                    type
                                  )
                                }
                              />
                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_city && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_city}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_city" /> */}
                              </Box>
                            </div>
                          </div>
                          {errors.plan_periods_in_days && (
                            <div className=" text-red-600">
                              {errors.plan_periods_in_days}
                            </div>
                          )}
                        </div>
                      );
                    })
                  : null}

                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">
                        Plan Period <span className=" text-red-600">*</span>
                      </p>
                      <div className="flex items-end">
                        <Input
                          className="w-full"
                          id="plan_periods_in_days"
                          type="number"
                          name="plan_periods_in_days"
                          required
                          onBlur={handleBlur}
                          error={!!errors.plan_periods_in_days}
                          value={values.plan_periods_in_days}
                          onChange={handleChange}
                        />
                        <p className="text-[gray]">Days</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            ) : null}
            <></>
          </div>

          <div
            className="bazaarField flex items-center gap-8 bazaarplan-form"
            style={{ width: "100%", display: "block" }}
          >
            {values.plan_choice === "PAID" ? (
              <>
                {/* <div className="grid grid-cols-2 gap-4 w-full"> */}
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingRight: "15px" }}>
                      <p className="fieldTitle">
                        Plan Name <span className=" text-red-600">*</span>
                      </p>
                      <Input
                        className="w-full"
                        id="plan_name"
                        type="text"
                        name="plan_name"
                        required
                        onBlur={handleBlur}
                        error={errors.plan_name}
                        value={values.plan_name}
                        onChange={handleChange}
                      />
                      {errors.plan_name && (
                        <div className=" text-red-600">{errors.plan_name}</div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingRight: "15px" }}>
                      <p className="fieldTitle">
                        Amount <span className=" text-red-600">*</span>
                      </p>
                      <Input
                        className="w-full"
                        id="amount"
                        type="number"
                        name="amount"
                        required
                        onBlur={handleBlur}
                        error={!!errors.amount}
                        value={values.amount ? values.amount : ""}
                        onChange={(e) => {
                          handleChange(e);
                          setPreviewData((prev: any) => ({
                            ...prev,
                            plantype: e?.target?.value,
                          }));
                        }}
                      />
                      {errors.amount && (
                        <div className=" text-red-600">{errors.amount}</div>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">Start Date</p>
                      <DatePicker
                        className="w-full"
                        // label="Start Date"
                        minDate={new Date()}
                        inputFormat="DD/MM/YYYY"
                        value={values.start_date}
                        onChange={(e: any) => {
                          if (e?.$y && e?.$M && e?.$D) {
                            setFieldValue(
                              "start_date",
                              e.$y + "-" + (e.$M + 1) + "-" + e.$D
                            );

                            if (
                              values.end_date &&
                              new Date(values.end_date).getTime()! >
                                new Date(values.start_date).getTime()
                            ) {
                              setFieldValue("end_date", "");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant={"standard"} />
                        )}
                      />
                      {errors.start_date && (
                        <div className=" text-red-600">{errors.start_date}</div>
                      )}
                    </div>
                  </Grid>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">End Date</p>
                      <DatePicker
                        className="w-full"
                        // label="End Date"
                        inputFormat="DD/MM/YYYY"
                        minDate={
                          values.start_date
                            ? moment(new Date(values.start_date)).add(1, "day")
                            : new Date()
                        }
                        value={values.end_date}
                        onChange={(e: any) => {
                          if (e?.$y && e?.$M && e?.$D) {
                            setFieldValue(
                              "end_date",
                              e.$y + "-" + (e.$M + 1) + "-" + e.$D
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant={"standard"} />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}></Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div>
                      <p className="title-main">
                        Bazaar <span className=" text-red-600">*</span>
                      </p>
                      <Autocomplete
                        multiple
                        id="bazaar-autocomplete"
                        options={AllBazaar.map((item: any) => ({
                          label: item.label,
                          value: item.id,
                        }))}
                        // value={bazaars}
                        onChange={(event: any, value: any) => {
                          handleChange(event, value);

                          // setBazaars(value);
                          const values = value.map((ele: any) => ele.id);
                          // return;
                          let data: any = [];
                          values.forEach((item: number) => {
                            data = [
                              ...data,
                              {
                                bazaar_id: item,
                                state:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.state || [],
                                district:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.district || [],
                                city:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.city || [],
                                allDistricts:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.allDistricts || [],
                                allCities:
                                  bazaarData.find(
                                    (ele: any) => ele.bazaar_id === item
                                  )?.allCities || [],
                              },
                            ];
                          });
                          setBazaarWiseData(data);
                          console.log("Bazaar_DATA", value);

                          // setBazaarWiseData(data);
                          // handleChange(event);
                          setFieldValue("bazaar", values);

                          // setselectbazzar(e.target.value);
                          setFieldValue("agent_assigned_state", "");
                          setFieldValue("agent_assigned_district", []);
                          setFieldValue("agent_assigned_city", []);
                        }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option.label} />
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" fullWidth />
                        )}
                      />
                      <Box sx={{ color: "red" }}>
                        {errors.bazaar && (
                          <div className=" text-red-600">{errors.bazaar}</div>
                        )}
                      </Box>
                    </div>
                  </Grid>
                </Grid>

                {AllBazaar.length
                  ? bazaarWiseData.map((item: any, index: number) => {
                      let bazaar: any = AllBazaar.find(
                        (ele: any) => ele.value === item.bazaar_id
                      );
                      console.log(
                        "Bazaar LOG ====> ",
                        bazaar,
                        bazaarWiseData,
                        AllBazaar
                      );
                      let allStates: any = bazaar.bazaar_state_names.map(
                        (item: any) => ({
                          label: item.state,
                          value: item.id,
                        })
                      );

                      let allDistricts: any =
                        bazaar.bazaar_district_names.filter((item: any) =>
                          bazaarWiseData[index]?.state?.includes(item.state)
                        ) || [];

                      let allCities: any =
                        bazaar.bazaar_city_names.filter((item: any) =>
                          bazaarWiseData[index]?.district?.includes(
                            item.district
                          )
                        ) || [];

                      return (
                        <div className="formContainer flex flex-col ml-[32px]">
                          <p className=" font-bold">{bazaar?.label}</p>

                          <div className="grid grid-cols-2 gap-4 w-full">
                            <div>
                              <p className="title-main">
                                Assigned States{" "}
                                <span className=" text-red-600">*</span>
                              </p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={true}
                                name="agent_assigned_state"
                                value={bazaarWiseData[index]?.state}
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  bazaarWiseData[index].state = e.target.value;
                                  bazaarWiseData[index].district =
                                    bazaarData.find(
                                      (ele: any) =>
                                        ele.state.includes(
                                          Number(e.target.value)
                                        ) && ele.bazaar_id === item.bazaar_id
                                    )?.district || [];
                                  bazaarWiseData[index].city =
                                    bazaarData.find(
                                      (ele: any) =>
                                        ele.state.includes(
                                          Number(e.target.value)
                                        ) && ele.bazaar_id === item.bazaar_id
                                    )?.city || [];
                                  setBazaarWiseData([...bazaarWiseData]);
                                  handleChange(e);
                                  getAllDis(
                                    {
                                      ids: e.target.value.join(","),
                                    },
                                    "multiple",
                                    index
                                  );
                                  setFieldValue("agent_assigned_district", []);
                                  setFieldValue("agent_assigned_city", []);
                                }}
                                renderValue={(selected) => {
                                  return allStates
                                    .filter((item: any) =>
                                      selected.includes(item.value)
                                    )
                                    .map((item: any) => item?.label)
                                    ?.join(", ");
                                }}
                              >
                                {allStates?.map((items: any) => (
                                  <MenuItem key={index} value={items.value}>
                                    <Checkbox
                                      checked={bazaarWiseData[
                                        index
                                      ]?.state?.includes(items.value)}
                                    />
                                    <ListItemText primary={items.label} />
                                  </MenuItem>
                                ))}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_state && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_state}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_state" /> */}
                              </Box>
                            </div>

                            <div>
                              <div className="Selectdistrict">
                                <DistrictPicker
                                  label={"Assigned District"}
                                  required
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allStates.map((item: any) => ({
                                    state: item.label,
                                    id: item.value,
                                  }))}
                                  selectedStates={[
                                    ...bazaarWiseData[index]?.state,
                                  ]}
                                  districts={bazaarWiseData[
                                    index
                                  ].allDistricts.map(
                                    (item: any, index: number) => {
                                      return {
                                        ...item,
                                        district:
                                          item?.district?.filter((ele: any) =>
                                            allDistricts
                                              .map((item: any) => item.id)
                                              .includes(ele.id)
                                          ) || [],
                                      };
                                    }
                                  )}
                                  selectedDistricts={
                                    bazaarWiseData[index]?.district
                                  }
                                  onSelectItem={(e: any, type: any) => {
                                    handleStateChange(
                                      bazaarWiseData[index]?.district,
                                      e,
                                      index,
                                      false,
                                      type
                                    );
                                    bazaarWiseData[index].city =
                                      bazaarData.find(
                                        (ele: any) =>
                                          ele.state.includes(
                                            Number(item.state)
                                          ) &&
                                          ele.bazaar_id === item.bazaar_id &&
                                          ele.district.some((element: any) =>
                                            bazaarWiseData[
                                              index
                                            ]?.district.includes(element)
                                          )
                                      )?.city || [];
                                    setBazaarWiseData([...bazaarWiseData]);
                                    setFieldValue("agent_assigned_city", []);
                                  }}
                                />
                              </div>

                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_district && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_district}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_district" /> */}
                              </Box>
                            </div>
                            <div>
                              <CityPickerNew
                                label={"Assigned Cities"}
                                hint={"Search"}
                                required
                                variant={"outlined"}
                                states={bazaarWiseData[index].allDistricts}
                                selectedStates={bazaarWiseData[index]?.district}
                                selectedDistricts={
                                  bazaarWiseData[index]?.district
                                }
                                districts={bazaarWiseData[index].allCities.map(
                                  (item: any, index: number) => {
                                    // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
                                    return {
                                      ...item,
                                      city:
                                        item?.city?.filter((ele: any) =>
                                          allCities
                                            .map((item: any) => item.id)
                                            .includes(ele.id)
                                        ) || [],
                                    };
                                  }
                                )}
                                selectedCity={bazaarWiseData[index]?.city}
                                selectedcity={bazaarWiseData[index]?.city}
                                onSelectItem={(
                                  id: any,
                                  type: { all: boolean }
                                ) =>
                                  handleStateChange(
                                    bazaarWiseData[index]?.city,
                                    id,
                                    index,
                                    true,
                                    type
                                  )
                                }
                              />
                              <Box sx={{ color: "red" }}>
                                {errors.agent_assigned_city && (
                                  <div className=" text-red-600">
                                    {errors.agent_assigned_city}
                                  </div>
                                )}
                                {/* <ErrorMessage name="agent_assigned_city" /> */}
                              </Box>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <p className="fieldTitle">
                        Plan Period <span className=" text-red-600">*</span>
                      </p>
                      <div className="flex items-end">
                        <Input
                          className="w-full"
                          id="plan_periods_in_days"
                          type="number"
                          name="plan_periods_in_days"
                          required
                          onBlur={handleBlur}
                          error={!!errors.plan_periods_in_days}
                          value={values.plan_periods_in_days}
                          onChange={handleChange}
                        />
                        <p className="text-[gray]">Days</p>
                      </div>
                      {errors.plan_periods_in_days && (
                        <div className=" text-red-600">
                          {errors.plan_periods_in_days}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {/* </div> */}
              </>
            ) : null}
            <></>
          </div>
        </div>

        <div className="mt-[20px] items-center">
          <p className="actionButton pt-[20px] pb-[20px]">Plan Features</p>

          <div className="grid md:grid-cols-2">
            <div className="grid md:grid-cols-2">
              <div
                style={{
                  paddingTop: "30px",
                  paddingRight: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={values?.whatsapp_notification}
                  onChange={(e) =>
                    handleChange({
                      target: { name: e.target.name, value: e.target.checked },
                    })
                  }
                  className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                  name="whatsapp_notification"
                  id="whatsapp_notification"
                />
                <label
                  htmlFor="whatsapp_notification"
                  className="fieldTitle !text-[16px]"
                >
                  Whatsapp Notifications
                </label>
              </div>

              {values?.whatsapp_notification && (
                <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                  <p className="fieldTitle">Number of Whatsapp Notifications</p>
                  <Input
                    className="w-full"
                    id="whatsapp_notification_limit"
                    type="number"
                    name="whatsapp_notification_limit"
                    required
                    error={errors.whatsapp_notification_limit}
                    value={
                      values.whatsapp_notification_limit
                        ? values.whatsapp_notification_limit
                        : ""
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setPreviewData((prev: any) => ({
                        ...prev,
                        whatsapp_notification_limit: e?.target?.value,
                      }));
                    }}
                  />
                  {errors.whatsapp_notification_limit && (
                    <div className=" text-red-600">
                      {errors.whatsapp_notification_limit}
                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  paddingTop: "30px",
                  paddingRight: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={values?.sms_notification}
                  onChange={(e) =>
                    handleChange({
                      target: { name: e.target.name, value: e.target.checked },
                    })
                  }
                  className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                  name="sms_notification"
                  id="sms_notification"
                />
                <label
                  htmlFor="sms_notification"
                  className="fieldTitle !text-[16px]"
                >
                  SMS Notifications
                </label>
              </div>

              {values?.sms_notification && (
                <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                  <p className="fieldTitle">Number of SMS Notifications</p>
                  <Input
                    className="w-full"
                    id="sms_notification_limit"
                    type="number"
                    name="sms_notification_limit"
                    required
                    error={errors.sms_notification_limit}
                    value={
                      values.sms_notification_limit
                        ? values.sms_notification_limit
                        : ""
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setPreviewData((prev: any) => ({
                        ...prev,
                        sms_notification_limit: e?.target?.value,
                      }));
                    }}
                  />
                  {errors.sms_notification_limit && (
                    <div className=" text-red-600">
                      {errors.sms_notification_limit}
                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  paddingTop: "30px",
                  paddingRight: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={tally}
                  onChange={(e) => handlechagetelly(e)}
                  className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                  name="plan_tally"
                  id="telly"
                />
                <label htmlFor="telly" className="fieldTitle !text-[16px]">
                  Add Tally
                </label>
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Branch</p>
                <Input
                  className="w-full"
                  id="branches"
                  type="number"
                  name="branches"
                  required
                  error={errors.branches}
                  value={values.branches ? values.branches : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      branches: e?.target?.value,
                    }));
                  }}
                />
                {errors.branches && (
                  <div className=" text-red-600">{errors.branches}</div>
                )}
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Agents</p>
                <Input
                  className="w-full"
                  id="agents_limit"
                  type="number"
                  name="agents_limit"
                  required
                  error={errors.agents_limit}
                  value={values.agents_limit ? values.agents_limit : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      agents_limit: e?.target?.value,
                    }));
                  }}
                />
                {errors.agents_limit && (
                  <div className=" text-red-600">{errors.agents_limit}</div>
                )}
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Products</p>
                <Input
                  className="w-full"
                  id="products_limit"
                  type="number"
                  name="products_limit"
                  required
                  error={errors.products_limit}
                  value={values.products_limit ? values.products_limit : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      products_limit: e?.target?.value,
                    }));
                  }}
                />
                {errors.products_limit && (
                  <div className=" text-red-600">{errors.products_limit}</div>
                )}
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Orders</p>
                <Input
                  className="w-full"
                  id="orders_limit"
                  type="number"
                  name="orders_limit"
                  required
                  error={errors.orders_limit}
                  value={values.orders_limit ? values.orders_limit : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      orders_limit: e?.target?.value,
                    }));
                  }}
                />
                {errors.orders_limit && (
                  <div className=" text-red-600">{errors.orders_limit}</div>
                )}
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Retailers</p>
                <Input
                  className="w-full"
                  id="retailers_limit"
                  type="number"
                  name="retailers_limit"
                  required
                  error={errors.retailers_limit}
                  value={values.retailers_limit ? values.retailers_limit : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      retailers_limit: e?.target?.value,
                    }));
                  }}
                />
                {errors.retailers_limit && (
                  <div className=" text-red-600">{errors.retailers_limit}</div>
                )}
              </div>

              <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                <p className="fieldTitle">Number of Users</p>
                <Input
                  className="w-full"
                  id="users_limit"
                  type="number"
                  name="users_limit"
                  required
                  error={errors.users_limit}
                  value={values.users_limit ? values.users_limit : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setPreviewData((prev: any) => ({
                      ...prev,
                      users_limit: e?.target?.value,
                    }));
                  }}
                />
                {errors.users_limit && (
                  <div className=" text-red-600">{errors.users_limit}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* {[...Array(groupCategories)].map((row: any, index: number) => ( */}
        {/* <div className="pb-[20px]"> */}
        {/* <div className="bazaarField flex items-center gap-8"> */}
        {/* <Grid container spacing={2}>
                <Grid item lg={3.25} md={5} sm={6} xs={7}>
                  <FormControl variant="standard" style={{ width: "100%", paddingRight: "15px" }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      name="plan_features"
                      value={addFeatures[index].feature}
                      onChange={(e) => handleChanges(e, index)}
                    // onChange={(e) => setFeature([ ...feature, parseInt(e.target.value) ])}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3.25} md={5} sm={6} xs={5}>
                  <FormControl variant="standard" className="w-1/2">
                    <div className="ActionLogo">
                      <EditIcon
                        className="cursor-pointer"
                        onClick={() => savePlan(index, addFeatures[index].id)}
                      />
                      <div className="divider"></div>
                      <DeleteOutlineIcon
                        className="cursor-pointer text-[red]"
                        onClick={() => hideTabs(true, index, addFeatures[index].id)}
                      />
                    </div>
                  </FormControl>
                </Grid>
              </Grid> */}
        {/* </div> */}
        {/* </div> */}
        {/* ))} */}

        {/* <div className="">
            <div
              onClick={addInputField}
              className="border-2 border-dashed border-[#5542F6] w-[280px] p-[10px] items-center text-center rounded-lg cursor-pointer"
            >
              <p className="addButton">+ Add More</p>
            </div>
          </div> */}
        <button
          className={
            id || (isValid && previewData?.plantype !== "")
              ? "flex mt-[25px] w-[250px] gap-5 items-center border-2 border-[#4E2FA9] rounded-[11px] border-dashed py-[8px] px-[20px] cursor-pointer"
              : "flex mt-[25px] w-[250px] gap-5 items-center border-2 border-[#4E2FA9] rounded-[11px] border-dashed py-[8px] px-[20px] cursor-not-allowed"
          }
          onClick={() => hendalPreview(values)}
          disabled={
            id || (isValid && previewData?.plantype != "") ? false : true
          }
        >
          <div className="w-[20px]">
            <img src={IcView} alt={"Logo"} />
          </div>
          <div className="privacyButton">Preview</div>
        </button>
        <div className="flex gap-5 py-[30px]">
          <ActionButton
            title="Cancel"
            variant="primary"
            onClick={() => (
              navigate("/plans"), localStorage.removeItem("setplandata")
            )}
          />
          <ActionButton
            disabled={props.isLocked}
            type="button"
            title={
              props.isLocked
                ? "Can't edit now as someone already bought it"
                : "Submit"
            }
            variant={props.isLocked ? "primary" : "default"}
            onClick={handleSubmit}
          />
        </div>
        {/* </form> */}
      </div>
    </LocalizationProvider>
  );
};

export { FreePlan };
